import { CSSProperties } from '@emotion/serialize';
import { Link, Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

export enum AgreementPolicies {
  privacyPolicy = 'Política de Privacidade',
  cookiePolicy = 'Política de Cookies',
  usageTerms = 'Termos de Uso',
  termsAndServices = 'Termos e Serviços',
}

interface AgreementTermsProps {
  mainSentenceOpts: {
    sentence: string;
    style?: TypographyProps;
    hasBreakLine?: boolean;
  };
  policies?: AgreementPolicies[];
  linkStyle?: CSSProperties;
}

const getAgreementsHref = (policy: AgreementPolicies) =>
  policy
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s/g, '-')
    .toLowerCase();

export const AgreementTerms: FC<AgreementTermsProps> = ({
  mainSentenceOpts,
  policies = [
    AgreementPolicies.privacyPolicy,
    AgreementPolicies.cookiePolicy,
    AgreementPolicies.usageTerms,
  ],
  linkStyle,
}) => (
  <Typography variant="body2" {...mainSentenceOpts.style}>
    {mainSentenceOpts.sentence}
    {mainSentenceOpts.hasBreakLine && <br />}
    {policies.map((policy, idx) => (
      <>
        {policies.length > 1 && (policies.length - 1 === idx ? ' e os' : idx !== 0 ? ',' : null)}{' '}
        <Link
          underline="none"
          key={policy}
          // eslint-disable-next-line react/forbid-component-props
          sx={{ textDecoration: 'underline', ...linkStyle }}
          href={`/${getAgreementsHref(
            policy === AgreementPolicies.termsAndServices ? AgreementPolicies.usageTerms : policy,
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {policy}
        </Link>
        {policies.length === 1 && ' definidos pela Veroo'}
      </>
    ))}
  </Typography>
);
