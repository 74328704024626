import { Box, CircularProgress, Dialog, DialogContent, Typography } from '@mui/material';
import { Transition } from '@web/common/components/dialog/dialog-transition';
import { FC } from 'react';

export interface LoadingModalProps {
  loading: boolean;
  message?: string;
}

export const LoadingModal: FC<LoadingModalProps> = ({ loading, message = 'Aguarde...' }) => {
  return (
    <Dialog open={loading} TransitionComponent={Transition}>
      <DialogContent className="flex items-center gap-4">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <CircularProgress />
          <Typography variant="h6">{message}</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
