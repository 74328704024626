import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { VerificationCode } from './verification-code';

export interface SignInCodeConfirmationProps {
  email: string;
  onCodeConfirmation: (code: string) => void;
  onResendCode: (callback: (success: boolean) => void) => void;
  onBackToEmail?: () => void;
}

const RESEND_TIMEOUT = 60;

export const SignInCodeConfirmation: FC<SignInCodeConfirmationProps> = ({
  email,
  onBackToEmail,
  onCodeConfirmation,
  onResendCode,
}) => {
  const [resend, setResend] = useState(RESEND_TIMEOUT);
  const [resendLoading, setResendLoading] = useState(false);
  useEffect(() => {
    const timer = window.setInterval(() => {
      setResend(prev => (prev > 0 ? prev - 1 : prev));
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  const handleResend = () => {
    if (resendLoading) return;
    setResendLoading(true);
    const timer = setTimeout(() => setResendLoading(false), 60_000);
    onResendCode(success => {
      if (success) setResend(RESEND_TIMEOUT);
      setResendLoading(false);
      clearTimeout(timer);
    });
  };
  return (
    <Box sx={{ maxWidth: '100%', textAlign: 'center' }}>
      <Typography variant="h5">Insira o código de acesso</Typography>
      <Typography variant="body2">Enviamos o código de acesso para seu email</Typography>
      <Box my={2}>
        <Typography variant="body2" textAlign="center" paragraph>
          Código enviado para <br />
          <strong>{email}</strong>
        </Typography>
        <VerificationCode codeLength={6} onValidCode={onCodeConfirmation} />
      </Box>
      {resend > 0 ? (
        <Typography variant="body2" paragraph mt={4}>
          Você pode requisitar um novo código em <br />
          <strong>{resend} segundos</strong>
        </Typography>
      ) : (
        <Button onClick={handleResend}>Reenviar código</Button>
      )}
      {onBackToEmail && (
        <div>
          <Button onClick={onBackToEmail}>Alterar email</Button>
        </div>
      )}
    </Box>
  );
};
