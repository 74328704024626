import styled from '@emotion/styled';
import { Grid, Typography, Divider } from '@mui/material';
import { FC } from 'react';

interface TextDividerProps {
  content: string;
}
const GridDivider = styled(Grid)`
  margin-top: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(2)};
`;
export const TextDivider: FC<TextDividerProps> = ({ content }) => {
  return (
    <GridDivider container justifyContent="center" alignItems="center">
      <Grid item xs={4}>
        <Divider />
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" color="textSecondary" align="center">
          {content}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Divider />
      </Grid>
    </GridDivider>
  );
};
