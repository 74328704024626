import * as Types from '../types';

import { gql } from '@apollo/client';
import { MutationErrorFieldsFragmentDoc } from '../fragments/_gen/mutation-error.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoginByEmailMutationVariables = Types.Exact<{
  input: Types.UserLoginByEmailInput;
}>;

export type LoginByEmailMutation = {
  userLoginByEmail?: {
    success?: boolean | null;
    errors?: Array<{ field?: string | null; message?: string | null }> | null;
  } | null;
};

export type LoginValidateMutationVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;

export type LoginValidateMutation = {
  userLoginValidate?: {
    success?: boolean | null;
    errors?: Array<{ field?: string | null; message?: string | null }> | null;
  } | null;
};

export const LoginByEmailDocument = gql`
  mutation LoginByEmail($input: UserLoginByEmailInput!) {
    userLoginByEmail(input: $input) {
      errors {
        ...mutationErrorFields
      }
      success
    }
  }
  ${MutationErrorFieldsFragmentDoc}
`;
export type LoginByEmailMutationFn = Apollo.MutationFunction<
  LoginByEmailMutation,
  LoginByEmailMutationVariables
>;

/**
 * __useLoginByEmailMutation__
 *
 * To run a mutation, you first call `useLoginByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginByEmailMutation, { data, loading, error }] = useLoginByEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginByEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginByEmailMutation, LoginByEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginByEmailMutation, LoginByEmailMutationVariables>(
    LoginByEmailDocument,
    options,
  );
}
export type LoginByEmailMutationHookResult = ReturnType<typeof useLoginByEmailMutation>;
export type LoginByEmailMutationResult = Apollo.MutationResult<LoginByEmailMutation>;
export type LoginByEmailMutationOptions = Apollo.BaseMutationOptions<
  LoginByEmailMutation,
  LoginByEmailMutationVariables
>;
export const LoginValidateDocument = gql`
  mutation LoginValidate($token: String!) {
    userLoginValidate(input: { token: $token }) {
      errors {
        ...mutationErrorFields
      }
      success
    }
  }
  ${MutationErrorFieldsFragmentDoc}
`;
export type LoginValidateMutationFn = Apollo.MutationFunction<
  LoginValidateMutation,
  LoginValidateMutationVariables
>;

/**
 * __useLoginValidateMutation__
 *
 * To run a mutation, you first call `useLoginValidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginValidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginValidateMutation, { data, loading, error }] = useLoginValidateMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoginValidateMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginValidateMutation, LoginValidateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginValidateMutation, LoginValidateMutationVariables>(
    LoginValidateDocument,
    options,
  );
}
export type LoginValidateMutationHookResult = ReturnType<typeof useLoginValidateMutation>;
export type LoginValidateMutationResult = Apollo.MutationResult<LoginValidateMutation>;
export type LoginValidateMutationOptions = Apollo.BaseMutationOptions<
  LoginValidateMutation,
  LoginValidateMutationVariables
>;
