import { FC } from 'react';
import MaskedInput from 'react-text-mask';

interface OwnProps {
  /** A reference to the input inside the mask field */
  inputRef: any;
}

const baseCNPJMask = [
  /[0-9]/,
  /[0-9]/,
  '.',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '.',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '/',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
];
const cpfMask = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '.',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '.',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
];

// Renders around a input field to apply a specific format of a phone number to it
export const DocMaskField: FC<OwnProps> = ({ inputRef, ...other }) => (
  <MaskedInput
    {...other}
    ref={inputRef}
    mask={(val: any) => {
      const numberVal = val.replace(/[^\d]+/g, '');
      return numberVal.length <= 11 ? cpfMask : baseCNPJMask;
    }}
  />
);
