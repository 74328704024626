import { useCallback, useState } from 'react';
import { captureException } from '../sentry';

const sleep = (ms: number) =>
  new Promise(resolve => {
    window.setTimeout(resolve, ms);
  });

export const useRecaptcha = () => {
  const [loading, setLoading] = useState(false);
  const execute = useCallback((action: string) => {
    setLoading(true);
    return new Promise<string>(async (resolve, reject) => {
      let i = 0;
      while (window.grecaptcha == null && i < 10) {
        // eslint-disable-next-line no-await-in-loop
        await sleep(500);
        i += 1;
      }
      if (window.grecaptcha == null) {
        reject(new Error('Recaptcha library not loaded'));
        setLoading(false);
        return;
      }
      window.grecaptcha.ready(async () => {
        try {
          const token = await window.grecaptcha.execute(
            '6LfVJ6wnAAAAADDbIudbm4MjWxlRS1gQOMvrog1A',
            { action },
          );
          resolve(token);
        } catch (e) {
          captureException(e);
          reject(e);
        } finally {
          setLoading(false);
        }
      });
    });
  }, []);
  return { execute, loading };
};
