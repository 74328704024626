import styled from '@emotion/styled';
import { Link } from '@mui/material';
import { OptimizedImage } from '@web/common/components/optimized-image';
import { externalEvents } from '@web/common/events/external-event';
import NextLink from 'next/link';
import { FC } from 'react';
import { SignInButtonProps } from './signin-interface';

const Button = styled.button`
  width: 100%;
  height: 3.5rem;
  margin-bottom: ${props => props.theme.spacing(1)};
  background-color: #fff;
  border-width: 0;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: ${props => props.theme.spacing(1, 2)};
  color: white;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: ${props => props.theme.shape.borderRadius}px;

  &:focus,
  &:hover {
    box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: #e5e5e5;
    box-shadow: none;
    transition-duration: 10ms;
  }
`;

const ButtonText = styled.span`
  color: rgba(0, 0, 0, 0.54);
  display: inline-block;
  margin-left: ${props => props.theme.spacing(2)};
  font-size: 16px;
  font-weight: bold;
  font-family: 'Roboto', arial, sans-serif;
`;

export const GoogleSignInButton: FC<SignInButtonProps> = ({
  next,
  btnLabel = 'Continuar com Google',
  mode = 'login',
}) => (
  <NextLink href={`/api/accounts/google/login/?process=login&next=${next ?? ''}`} passHref>
    <Link underline="none" onClick={() => externalEvents[mode]('google')}>
      <Button>
        <OptimizedImage src="/icons/google-logo.svg" ext="svg" width="24" height="24" />
        <ButtonText>{btnLabel}</ButtonText>
      </Button>
    </Link>
  </NextLink>
);
