import styled from '@emotion/styled';
import { Box, Button, Divider, Grid } from '@mui/material';
import { safeAsync } from '@web/common/perform-safe-async';
import { loginConstraints } from '@web/common/validation/constraints/login-form';
import { FinalityKind } from 'core/lib/graphql/types';
import { useLoginByEmailMutation, useLoginValidateMutation } from 'core/lib/graphql/_gen/auth';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { FC, FunctionComponent, useState } from 'react';
import { useRecaptcha } from '@web/common/hooks/use-recaptcha';
import { captureException } from '@web/common/sentry';
import { showDangerSnackbar } from '@web/common/show-snackbar';
import { ButtonLoader } from '../button-loader';
import { LoadingModal } from '../dialog/loading-modal';
import { DocMaskField } from '../form/doc-mask';
import { DefaultForm } from '../form/form';
import { PhoneNumberField } from '../form/phone-number-field';
import { TextField } from '../form/text-field';
import { ContainerLoader } from '../loaders/container-loader';
import { TextDivider } from '../text-divider';
import { AgreementTerms } from './agreement-terms';
import { GoogleSignInButton } from './google-sign-in-button';
import { SignInCodeConfirmation } from './sign-in-code-confirmation';

const EnrollOptionsContainer = styled.div`
  & > a {
    display: block;
    margin-bottom: ${props => props.theme.spacing(2)};
  }
`;

interface SignInContainerProps {
  ssoOptions?: {
    googleNextUrl?: string;
    bottomDividerText?: string;
  };
  isRegister?: boolean;
  isCheckout?: boolean;
  btnLabel?: string;
  hasAgreementTerms?: boolean;
  hasRegisterButton?: boolean;
  hasLoginButton?: boolean;
  defaultNextUrl?: string;
  finality?: FinalityKind;
  onLoginSuccess?: () => void;
}

export const SignInContainer: FC<SignInContainerProps> = ({
  ssoOptions,
  btnLabel,
  hasAgreementTerms = true,
  hasRegisterButton,
  hasLoginButton,
  isRegister,
  isCheckout,
  defaultNextUrl = '/admin/subscription',
  finality,
  onLoginSuccess,
}) => {
  const { query, push } = useRouter();
  const mainBtnLabel = btnLabel ?? (isRegister ? 'Cadastrar' : 'Entrar');
  const nextUrl = typeof query.next === 'string' ? query.next : defaultNextUrl;
  const [email, setEmail] = useState<string | null>(null);
  const [login, loginQ] = useLoginByEmailMutation();
  const [redirecting, setRedirecting] = useState(false);
  const [loginValidate, loginValidateQ] = useLoginValidateMutation();
  const { execute, loading } = useRecaptcha();
  const handleLogin = async (values: { email: string; doc?: string; phone?: string }) => {
    const doc = values.doc != null ? values.doc.replace(/[^0-9]/gi, '') : undefined;
    setEmail(null);
    try {
      const token = await execute('signIn');
      safeAsync(() => login({ variables: { input: { ...values, doc, token } } }), {
        successCondition: res => res.data?.userLoginByEmail?.success ?? false,
        errorMessage: err =>
          err ?? isRegister
            ? 'Erro ao tentar se cadastrar. Caso esse erro persista entre em contato conosco nos canais de atendimento.'
            : 'Erro ao tentar entrar na sua conta Veroo. Caso esse erro persista entre em contato conosco nos canais de atendimento.',
        onSuccess: () => {
          setEmail(values.email);
        },
      });
    } catch (e) {
      captureException(e);
      showDangerSnackbar(
        isRegister
          ? 'Erro ao tentar se cadastrar. Caso esse erro persista entre em contato conosco nos canais de atendimento.'
          : 'Erro ao tentar entrar na sua conta Veroo. Caso esse erro persista entre em contato conosco nos canais de atendimento.',
      );
    }
  };
  const handleCodeConfirmation = (code: string) => {
    safeAsync(() => loginValidate({ variables: { token: code } }), {
      successCondition: res => res.data?.userLoginValidate?.success ?? false,
      errorMessage: err => err ?? 'Código inválido',
      successMessage: () => 'Código validado com sucesso. Em breve você será redirecionado.',
      onSuccess: async () => {
        setRedirecting(true);
        onLoginSuccess?.();
        await push({
          pathname: `/api/user/login/${code}`,
          query: { next: nextUrl, ...(finality != null ? { finality } : {}) },
        });
        setRedirecting(false);
      },
    });
  };
  const handleResendCode = async (callback: (success: boolean) => void) => {
    if (email == null) return;
    try {
      const token = await execute('signInResend');
      await safeAsync(() => login({ variables: { input: { email, token } } }), {
        successCondition: res => res.data?.userLoginByEmail?.success ?? false,
        errorMessage: err =>
          err ?? 'Erro ao tentar entrar na sua conta Veroo. Tente novamente mais tarde.',
        onSuccess: () => callback(true),
        onError: () => callback(false),
      });
    } catch (e) {
      captureException(e);
      showDangerSnackbar('Erro ao tentar entrar na sua conta Veroo. Tente novamente mais tarde.');
    }
  };
  return (
    <Box position="relative">
      {email != null ? (
        <ContainerLoader loading={loginValidateQ.loading}>
          <SignInCodeConfirmation
            email={email}
            onResendCode={handleResendCode}
            onCodeConfirmation={handleCodeConfirmation}
            onBackToEmail={() => {
              setEmail(null);
            }}
          />
        </ContainerLoader>
      ) : (
        <>
          {ssoOptions && (
            <>
              <EnrollOptionsContainer>
                {ssoOptions.googleNextUrl && <GoogleSignInButton next={ssoOptions.googleNextUrl} />}
              </EnrollOptionsContainer>
              <Box my={4}>
                {ssoOptions.bottomDividerText ? (
                  <TextDivider content={ssoOptions.bottomDividerText} />
                ) : (
                  <Divider />
                )}
              </Box>
            </>
          )}
          <DefaultForm
            onSubmit={handleLogin}
            constraint={loginConstraints}
            render={() => (
              <>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    label="Email"
                    variant="outlined"
                    placeholder="example@mail.com"
                    fullWidth
                    InputProps={{ type: 'email' }}
                  />
                </Grid>
                {isRegister && (
                  <>
                    <Grid item xs={12}>
                      <PhoneNumberField
                        name="phone"
                        label="Whatsapp"
                        placeholder="(99) 99999-9999"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="doc"
                        label="CPF/CNPJ"
                        type="tel"
                        fullWidth
                        variant="outlined"
                        InputProps={{ inputComponent: DocMaskField as FunctionComponent }}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <ButtonLoader
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                    data-e2e="sign-in-submit-btn"
                    label={mainBtnLabel}
                    loading={loginQ.loading || loading}
                  />
                </Grid>
                {hasRegisterButton && !isRegister && (
                  <Grid item xs={12}>
                    <ChangeSignInButton
                      isCheckout={isCheckout}
                      query={query}
                      url="/checkout/sign-up"
                      label="Cadastre-se"
                    />
                  </Grid>
                )}
                {hasLoginButton && isRegister && (
                  <Grid item xs={12}>
                    <ChangeSignInButton
                      isCheckout={isCheckout}
                      query={query}
                      url="/checkout/sign-in"
                      label="
                        Já tenho uma conta!"
                    />
                  </Grid>
                )}
              </>
            )}
          />
          {hasAgreementTerms && (
            <Box mt={5}>
              <AgreementTerms
                mainSentenceOpts={{ sentence: 'Ao entrar no sistema você concorda com a' }}
              />
            </Box>
          )}
        </>
      )}
      <LoadingModal loading={redirecting} message="Redirecionando..." />
    </Box>
  );
};

export interface ChangeSignInButtonProps {
  isCheckout?: boolean;
  query: ParsedUrlQuery;
  url: string;
  label: string;
}

const ChangeSignInButton: FC<ChangeSignInButtonProps> = ({ isCheckout, query, url, label }) => (
  <NextLink
    href={{ pathname: url, query: { ...query, ...(!isCheckout && { isRegister: 1 }) } }}
    passHref
  >
    <Button fullWidth color="primary" variant="outlined" size="large">
      {label}
    </Button>
  </NextLink>
);
