import { Box, TextField } from '@mui/material';
import { ChangeEvent, FC, useRef, useState } from 'react';

export interface VerificationCodeProps {
  codeLength: number;
  onCodeChange?: (code: string) => void;
  onValidCode?: (code: string) => void;
}

export const VerificationCode: FC<VerificationCodeProps> = ({
  codeLength,
  onCodeChange,
  onValidCode,
}) => {
  const [code, setCode] = useState<string[]>(new Array(codeLength).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const handleSetCodeIndex = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length > 1) {
      const values = value.split('');
      const next = new Array(codeLength).fill('').map((_, i) => values[i] || '');
      setCode(next);
      onCodeChange?.(next.join(''));
      if (next.length === codeLength) onValidCode?.(next.join(''));
      const nextFocus = values.length === codeLength ? codeLength - 1 : values.length;
      inputRefs.current[nextFocus]?.focus();
    } else {
      setCode(prev => {
        const next = [...prev];
        next.splice(index, 1, value);
        const nextString = next.join('');
        onCodeChange?.(nextString);
        if (nextString.length === codeLength) onValidCode?.(nextString);
        return next;
      });
      if (index + 1 === codeLength || value === '') return;
      inputRefs.current[index + 1]?.focus();
    }
  };
  const handleKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && index > 0 && code[index] === '') {
      inputRefs.current[index - 1]?.focus();
    }
  };
  return (
    <Box gap={1} display="flex">
      {Array.from(new Array(codeLength)).map((_, idx) => (
        <TextField
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          type="tel"
          value={code[idx]}
          inputProps={{
            maxLength: codeLength,
            style: {
              textAlign: 'center',
              fontSize: '32px',
              paddingBlock: 0,
              fontFamily: 'sans-serif',
            },
          }}
          InputProps={{
            inputRef: (el: HTMLInputElement) => {
              inputRefs.current[idx] = el;
            },
            type: 'tel',
          }}
          onChange={handleSetCodeIndex(idx)}
          onKeyDown={handleKeyDown(idx)}
          sx={{ width: '56px', height: '56px' }}
        />
      ))}
    </Box>
  );
};
