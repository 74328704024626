import { Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref } from 'react';

export const Transition = forwardRef(
  // eslint-disable-next-line react/require-default-props
  (props: TransitionProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...(props as any)} />
  ),
);
